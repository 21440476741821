import * as actionPlanActions from "../../store/actions/actionPlanAction";
import * as jobActions from "../../store/actions/jobAction";
import changeInput from "../../utils/changeInput";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-add-action.css";
import { faEye, faEyeSlash, faFilter, faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Combobox from "react-widgets/Combobox";
import { Button, ButtonGroup, Col, Container, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

var _ = require("lodash");

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const wrapperStyle = {
    borderRadius: "10px",
    width: "700px",
    background: "#F5F5F5",
    paddingLeft: "63px",
    paddingRight: "63px",
    paddingTop: "35px",
    paddingBottom: "35px",
};

const btnGroupStyle = {
    height: "52px",
    width: "252px",
    fontSize: "14px",
};
class FormAddAction extends React.Component {
    static displayName = FormAddAction.name;

    constructor(props) {
        super(props);
        this.state = {
            addMode: "Custom",
            firstName: "",
            lastName: "",
            actionType: "",
            description: "",
            targetDate: "",
            duration: "",
            website: "",
            otherSkills: "",
            optionsActionTypes: this.props.optionsActionTypes,
            optionsActionSkills: this.props.optionsActionSkills,
            selectedOtherSkill: null,
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                actionType: {
                    validation: {
                        required: true,
                    },
                    value: this.props.optionsActionTypes && this.props.optionsActionTypes.length > 0 && this.props.optionsActionTypes[0].name,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                description: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                target: {
                    validation: {
                        required: this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false,
                        isDateTime: true,
                    },
                    value: null,
                    valid: !(this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false),
                    isValidFormat: true,
                    touched: false,
                },
                duration: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                durationHours: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                website: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            formListed: {
                description: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                target: {
                    validation: {
                        required: this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false,
                        isDateTime: true,
                    },
                    value: null,
                    valid: !(this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false),
                    isValidFormat: true,
                    touched: false,
                },
            },
            selectedSkills: [],
            pickedOtherSkills: [],
            selectedOtherSkills: [],
            isFormValid: false,
            isFormListedValid: false,
            actionPlanUpdating: false,
            alerts: [],
            interventionsFilterGapsOnly: true,
            interventionsFilter: {
                companyId: null,
                skill: null,
                gaps: [],
                jobId: null,
            },
            interventionsLoaded: false,
            interventions: [],
            jobs: [],
            selectedIntervention: null,
            loading: false,
            showInterventionDetail: false,
            shownIntervention: null,
            showFilter: false,
            providers: this.props.providers,
            selectedProviderId: null,
            selectedInterventionSkill: null,
            showHiddenInterventions: false,
            noSkillConfirmationPass: false,
            showActionNoSkillConfirmationDialog: false,
        };
        this.handleSkillCheckedChange = this.handleSkillCheckedChange.bind(this);
        this.handleOtherSkillCheckedChange = this.handleOtherSkillCheckedChange.bind(this);
        this.handleChangeOtherSkill = this.handleChangeOtherSkill.bind(this);
        this.handleAddOtherSkill = this.handleAddOtherSkill.bind(this);

        this.handleGetInterventions = this.handleGetInterventions.bind(this);
        this.handleShowInterventionDetail = this.handleShowInterventionDetail.bind(this);
        this.handleSelectIntervention = this.handleSelectIntervention.bind(this);
        this.togleInterventionDetailModal = this.togleInterventionDetailModal.bind(this);
        this.submitFormListed = this.submitFormListed.bind(this);
        this.handleTogleShowFilter = this.handleTogleShowFilter.bind(this);
        this.interventionFormPanel = this.interventionFormPanel.bind(this);
        this.handleChangeGapsFilterSelection = this.handleChangeGapsFilterSelection.bind(this);
        this.handleChangeProviderSelection = this.handleChangeProviderSelection.bind(this);
        this.handleChangeSkillInterventionSelection = this.handleChangeSkillInterventionSelection.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        const selectedDefaultSkill = this.props.selectedDefaultSkill;

        const { interventionsFilter } = this.state;
        const { lorProfiles, skillProfiles, jobLorProfiles, jobSkillProfiles, categories, showHiddenSkills } = this.props;

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        let gaps = [];

        const showUnActionedOnly = true;

        if (selectedDefaultSkill) {
            gaps.push({
                skillsCode: selectedDefaultSkill.skillCode,
                level: selectedDefaultSkill.skillLevel,
            });
        } else {
            if (showUnActionedOnly) {
                gaps.push(
                    ...jobLorProfiles
                        .filter((it) => !it.actioned)
                        .map((it) => {
                            if (!lorProfiles.find((prof) => prof.lorCode == it.lorCode) || (lorProfiles.find((prof) => prof.lorCode == it.lorCode) && it.level > lorProfiles.find((prof) => prof.lorCode == it.lorCode).level)) {
                                return {
                                    skillsCode: it.lorCode,
                                    level: it.level,
                                };
                            }
                        })
                        .filter((it) => it)
                );

                gaps.push(
                    ...jobSkillProfiles
                        .filter((it) => !it.actioned && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(it.skillCode))))
                        .map((it) => {
                            if (!skillProfiles.find((prof) => prof.skillCode == it.skillCode && prof.level == it.level) || skillProfiles.find((prof) => prof.skillCode == it.skillCode && prof.level == it.level && prof.numericVal < 1)) {
                                return {
                                    skillsCode: it.skillCode,
                                    level: it.level,
                                };
                            }
                        })
                        .filter((it) => it)
                );
            } else {
                gaps.push(
                    ...[
                        ...jobLorProfiles.map((it) => {
                            return {
                                skillsCode: it.lorCode,
                                level: it.level,
                            };
                        }),
                        ...jobSkillProfiles
                            .filter((it) => showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(it.skillCode)))
                            .map((it) => {
                                return {
                                    skillsCode: it.skillCode,
                                    level: it.level,
                                };
                            }),
                    ]
                );
            }
        }

        interventionsFilter.gaps = gaps;
        this.setState({ interventionsFilter });

        if (selectedDefaultSkill) {
            const selectedSkills = this.state.selectedSkills;
            this.setState({ selectedSkills: [...selectedSkills, selectedDefaultSkill] });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showHiddenSkills !== this.props.showHiddenSkills && this.props.showHiddenSkills == false) {
            const { categories } = this.props;
            const { selectedSkills } = this.state;

            const availableSkillCodes = categories
                ? categories.flatMap((category, index) => {
                      return category.subCategories.flatMap((subCategory, index) => {
                          return subCategory.skills
                              .filter((it) => !it.isHidden)
                              .flatMap((skill, index) => {
                                  return skill.skillsCode;
                              });
                      });
                  })
                : [];

            this.setState({ selectedSkills: [...selectedSkills.filter((it) => availableSkillCodes.includes(it.skillCode))] });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeDate(name, value) {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    submitForm = () => {
        const { selectedSkills, selectedOtherSkills, noSkillConfirmationPass } = this.state;

        if (selectedSkills && selectedSkills.length == 0 && selectedOtherSkills && selectedOtherSkills.length == 0 && noSkillConfirmationPass == false) {
            this.setState({ showActionNoSkillConfirmationDialog: true });
        } else {
            const actionRequest = {
                personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
                actionType: this.state.form.actionType.value,
                name: this.state.form.name.value,
                description: this.state.form.description.value,
                status: "ToDo",
                targetCompletionOn: this.state.form.target.value,
                duration: this.state.form.duration.value,
                durationHours: this.state.form.durationHours.value,
                link: this.state.form.website.value,
                skills: [...selectedSkills, ...selectedOtherSkills].map((skill) => {
                    return {
                        name: skill.skillName,
                        code: skill.skillCode,
                        level: skill.skillLevel,
                        type: skill.skillType,
                    };
                }),
            };

            this.setState({ actionPlanUpdating: true });
            Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.createManagedPeopleAction(actionRequest) : this.props.onActionPlan.createAction(actionRequest)).then(() => {
                if (!this.props.actionPlanUpdating) {
                    if (this.props.actionPlanUpdatingError) {
                        this.props.generateAlert("danger", this.props.actionPlanUpdatingError);
                    } else {
                        this.props.handleActionPlanAdded(this.props.addedAction);
                    }
                }
                this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
            });
        }
    };

    handleActionClick = (addMode) => {
        const { form } = this.state;

        this.setState(
            {
                addMode,
                form,
                selectedIntervention: null,
            },
            () => {
                if (addMode == "Listed" && !this.state.interventionsLoaded) {
                    this.handleGetJobsWithAction();
                    this.handleGetInterventions();
                }
            }
        );
    };

    handleSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedSkills = this.state.selectedSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);
            const index = selectedSkills.indexOf(unSelectedSkill);
            if (index !== -1) {
                selectedSkills.splice(index, 1);
            }

            this.setState({ selectedSkills });
        } else {
            this.setState({ selectedSkills: [...selectedSkills, selectedSkill] });
        }
    }

    handleOtherSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedOtherSkills = this.state.selectedOtherSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);
            const index = selectedOtherSkills.indexOf(unSelectedSkill);
            if (index !== -1) {
                selectedOtherSkills.splice(index, 1);
            }

            this.setState({ selectedOtherSkills });
        } else {
            this.setState({ selectedOtherSkills: [...selectedOtherSkills, selectedSkill] });
        }
    }

    // handleChangeOtherSkill = (event) => {
    //     this.setState({ selectedOtherSkill: event.target.value })
    // }

    handleChangeOtherSkill = (skill) => {
        if (skill) {
            this.setState({ selectedOtherSkill: `${skill.skillCode}-${skill.skillLevel}` });
        }
    };

    handleAddOtherSkill() {
        const selectedOtherSkill = this.state.selectedOtherSkill;
        let { optionsActionSkills, pickedOtherSkills, selectedOtherSkills } = this.state;

        if (selectedOtherSkill && selectedOtherSkill !== "") {
            let optionsActionSkill = optionsActionSkills.find((it) => it.skills.some((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill));
            if (optionsActionSkill) {
                let skill = optionsActionSkill.skills.find((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill);
                if (skill) {
                    skill.selected = true;

                    pickedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });

                    selectedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });
                }

                this.setState({ optionsActionSkills, selectedOtherSkill: "", pickedOtherSkills, selectedOtherSkills });
            }
        }
    }

    handleGetJobsWithAction = () => {
        this.setState({ loading: true });
        this.props.onJob.getJobsWithAction().then(() => {
            if (!this.props.jobLoading && !this.props.jobError) {
                let currentJob = null;
                let futureJob = null;

                if (this.props.optionsJob && this.props.optionsJob.length > 0) {
                    currentJob = this.props.optionsJob.find((it) => it.type == "Current");
                    futureJob = this.props.optionsJob.find((it) => it.type == "Future");
                }

                const jobs = this.props.jobs.map((job) => {
                    return {
                        value: job.id,
                        label: job.name,
                        priority: currentJob && currentJob.id == job.id ? 1 : futureJob && futureJob.id == job.id ? 2 : 3,
                    };
                });
                this.setState({
                    jobs: [
                        {
                            value: "",
                            label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaActionPredefinedFilterRequirementPlaceholder", "=== All Requirements ==="),
                        },
                        ...jobs.sort((a, b) => (`${a.priority}${a.label}` > `${b.priority}${b.label}` ? 1 : -1)),
                    ],
                });
            } else {
                console.log("this.props.jobError", this.props.jobError);
            }

            this.setState({ loading: this.props.jobLoading });
        });
    };

    handleGetInterventions() {
        this.setState({ loading: true });

        const { interventionsFilterGapsOnly, interventionsFilter } = this.state;
        const gaps = interventionsFilterGapsOnly === true || interventionsFilterGapsOnly === "true" ? interventionsFilter.gaps : [];

        this.props.onActionPlan
            .getInterventions({
                companyId: interventionsFilter.companyId,
                skill: interventionsFilter.skill,
                gaps,
                jobId: interventionsFilter.jobId,
            })
            .then(() => {
                if (!this.props.interventionsLoading && !this.props.interventionsError) {
                    this.setState({ interventions: this.props.interventions });
                } else {
                    console.log("this.props.interventionsError", this.props.interventionsError);
                }

                this.setState({ interventionsLoaded: true, loading: this.props.interventionsLoading });
            });
    }

    handleShowInterventionDetail(intervention) {
        this.setState({ showInterventionDetail: true, shownIntervention: intervention });
    }

    togleInterventionDetailModal() {
        const showInterventionDetail = this.state.showInterventionDetail;
        this.setState({ showInterventionDetail: !showInterventionDetail });
    }

    handleFormListedChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.formListed, name, value);

        this.setState({
            formListed: updateState.form,
            isFormListedValid: updateState.valid,
        });
    };

    handleFormListedChangeDate(name, value) {
        const updateState = changeInput(this.state.formListed, name, value);

        this.setState({
            formListed: updateState.form,
            isFormListedValid: updateState.valid,
        });
    }

    handleSelectIntervention(intervention) {
        this.setState({ selectedIntervention: intervention });
    }

    submitFormListed() {
        const { selectedIntervention } = this.state;

        const actionRequest = {
            personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
            actionType: selectedIntervention.actionType,
            name: selectedIntervention.name,
            description: this.state.formListed.description.value,
            status: "ToDo",
            targetCompletionOn: this.state.formListed.target.value,
            duration: selectedIntervention.duration,
            durationHours: selectedIntervention.durationHours,
            link: selectedIntervention.link,
            interventionId: selectedIntervention.id,
            interventionName: selectedIntervention.name,
            interventionDescription: selectedIntervention.description,
            interventionProviderName: selectedIntervention.company.name,
            interventionIsValidated: selectedIntervention.isValidated,
            skills: [
                ...selectedIntervention.lorLevels.map((level) => {
                    return {
                        name: level.lorName,
                        code: level.lorCode,
                        level: level.level,
                        type: "Lor",
                    };
                }),
                ...selectedIntervention.levels.map((level) => {
                    return {
                        name: level.skillsName,
                        code: level.skillsCode,
                        level: level.level,
                        type: "Skill",
                    };
                }),
            ],
        };

        this.setState({ actionPlanUpdating: true });
        Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.createManagedPeopleAction(actionRequest) : this.props.onActionPlan.createAction(actionRequest)).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError);
                } else {
                    this.props.handleActionPlanAdded(this.props.addedAction);
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    }

    modalInterventionDetail() {
        const { showInterventionDetail, shownIntervention } = this.state;

        return (
            <Modal isOpen={showInterventionDetail} toggle={this.togleInterventionDetailModal}>
                <ModalHeader toggle={this.togleInterventionDetailModal}>
                    <ContentTranslator page="SfiaPlan" name="SfiaInterventionInfoTitle" contentText="Action Info" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionProvider" contentText="Provider" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.company && shownIntervention.company.name} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionName" contentText="Name" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.name} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionDescription" contentText="Description" />
                            </Label>
                            <Col sm={8}>
                                <Input type="textarea" value={shownIntervention && shownIntervention.description} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionWebsite" contentText="Website" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.link} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionDurationWithInterval" contentText="Duration(Days)" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.duration} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionDurationHoursWithInterval" contentText="Duration(Hours)" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.durationHours} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={4} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionVersion" contentText="Version Number" />
                            </Label>
                            <Col sm={8}>
                                <Input value={shownIntervention && shownIntervention.versionNumber} disabled />
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleInterventionDetailModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleTogleShowFilter() {
        this.setState({ showFilter: !this.state.showFilter });
    }

    interventionFormPanel() {
        const { providers, optionsActionSkills, jobs } = this.state;
        const { categories, showHiddenSkills } = this.props;

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden || showHiddenSkills)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        const spreadedActionSkills = [].concat.apply(
            [],
            optionsActionSkills
                .filter((it) => it.skills.some((skill) => !skill.selected && !skill.actioned))
                .map((it, groupIndex) => {
                    return it.skills
                        .filter((skill) => !skill.selected && !skill.actioned && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(skill.skillCode))))
                        .map((skill) => {
                            return {
                                groupIndex,
                                skillName: skill.skillName,
                                skillCode: skill.skillCode,
                                skillLabel: skill.skillLabel,
                                skillLevel: skill.skillLevel,
                                skillType: skill.skillType,
                            };
                        });
                })
        );

        const spreadedProviders = [
            { id: "", name: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaActionPredefinedFilterProviderPlaceholder", "=== All Providers ==="), isPreferred: false },
            ...providers
                .sort((a, b) => b.isPreferred - a.isPreferred)
                .map((provider) => {
                    return {
                        id: provider.id,
                        name: provider.name + (provider.isPreferred ? ` (${this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanPreferred", "preferred")})` : ""),
                        isPreferred: provider.isPreferred,
                    };
                }),
        ];

        return (
            <React.Fragment>
                <FormGroup row>
                    <Label sm={3} className="font-weight-bold" for="lblGaps">
                        <ContentTranslator page="SfiaPlan" name="SfiaActionPredefinedFilterGaps" contentText="Gaps" />
                    </Label>
                    <Col sm={9}>
                        <Combobox
                            onSelect={(value) =>
                                this.handleChangeGapsFilterSelection({
                                    target: {
                                        value: value.value,
                                    },
                                })
                            }
                            data={[
                                { value: true, label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanPredefinedFilterGapsOnly", "Gaps Only") },
                                { value: false, label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanPredefinedFilterGapsShowAll", "Show All") },
                            ]}
                            textField="label"
                            dataKey="value"
                            defaultValue={{ value: true, label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanPredefinedFilterGapsOnly", "Gaps Only") }}
                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3} className="font-weight-bold" for="lblGaps">
                        <ContentTranslator page="SfiaPlan" name="SfiaActionPredefinedFilterProvider" contentText="Select Provider" />
                    </Label>
                    <Col sm={9}>
                        <Combobox
                            data={spreadedProviders}
                            onSelect={(value) =>
                                this.handleChangeProviderSelection({
                                    target: {
                                        value: value.id,
                                    },
                                })
                            }
                            textField="name"
                            dataKey="id"
                            defaultValue={spreadedProviders[0]}
                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3} className="font-weight-bold" for="lblGaps">
                        <ContentTranslator page="SfiaPlan" name="SfiaActionPredefinedFilterSkill" contentText="Select Skill" />
                    </Label>
                    <Col sm={9}>
                        <Combobox
                            data={spreadedActionSkills.sort((a, b) => a.groupIndex - b.groupIndex)}
                            onChange={(value) => {
                                value == "" &&
                                    this.handleChangeSkillInterventionSelection({
                                        target: {
                                            value: "",
                                        },
                                    });
                            }}
                            onSelect={(value) =>
                                this.handleChangeSkillInterventionSelection({
                                    target: {
                                        value: value.skillCode + value.skillLevel,
                                    },
                                })
                            }
                            textField="skillLabel"
                            groupBy="skillName"
                            filter="contains"
                            placeholder={this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaActionPredefinedFilterSkillPlaceholder", "=== Select Skill ===")}
                            autoSelectMatches={true}
                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                        />
                    </Col>
                </FormGroup>
                {jobs && jobs.length > 0 && (
                    <FormGroup row>
                        <Label sm={3} className="font-weight-bold" for="lblGaps">
                            <ContentTranslator page="SfiaPlan" name="SfiaActionPredefinedFilterRequirement" contentText="Select Requirement" />
                        </Label>
                        <Col sm={9}>
                            <Combobox
                                data={jobs}
                                onSelect={(value) =>
                                    this.handleChangeJobSelection({
                                        target: {
                                            value: value.value,
                                        },
                                    })
                                }
                                textField="label"
                                dataKey="value"
                                defaultValue={jobs[0]}
                                style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                            />
                        </Col>
                    </FormGroup>
                )}
                <FormGroup row>
                    <Label sm={3} className="font-weight-bold"></Label>
                    <Col sm={9}>
                        <Input type="checkbox" id="chkShowHidden" checked={this.state.showHiddenInterventions} onChange={this.handleUpdateShowHiddenInterventionFilter} />{" "}
                        <Label check for="chkShowHidden">
                            <ContentTranslator page="SfiaPlan" name="SfiaActionPredefinedFilterShowHiddenInterventions" contentText="Show hidden interventions" />
                        </Label>
                    </Col>
                </FormGroup>
            </React.Fragment>
        );
    }

    handleChangeGapsFilterSelection = (event) => {
        this.setState(
            {
                interventionsFilterGapsOnly: event.target.value,
            },
            () => {
                this.handleGetInterventions();
            }
        );
    };

    handleChangeProviderSelection = (event) => {
        const interventionsFilter = this.state.interventionsFilter;
        const selectedProviderId = event.target.value;

        if (selectedProviderId && selectedProviderId != "") {
            interventionsFilter.companyId = selectedProviderId;
        } else {
            interventionsFilter.companyId = null;
        }

        this.setState(
            {
                selectedProviderId,
                interventionsFilter,
            },
            () => {
                this.handleGetInterventions();
            }
        );
    };

    handleChangeSkillInterventionSelection = (event) => {
        const skillCodeLevel = event.target.value;
        const { optionsActionSkills, interventionsFilter } = this.state;
        let selectedInterventionSkill;

        if (skillCodeLevel != "") {
            const optionsActionSkill = optionsActionSkills.find((it) => it.skills.some((skill) => skill.skillCode + skill.skillLevel == skillCodeLevel || "(LoR)-" + skill.skillCode + skill.skillLevel == skillCodeLevel));

            if (optionsActionSkill) {
                const skill = optionsActionSkill.skills.find((skill) => skill.skillCode + skill.skillLevel == skillCodeLevel || "(LoR)-" + skill.skillCode + skill.skillLevel == skillCodeLevel);
                if (skill) {
                    selectedInterventionSkill = skill.skillCode + skill.skillLevel;
                    interventionsFilter.skill = {
                        skillsCode: skill.skillCode,
                        level: skill.skillLevel,
                    };
                } else {
                    interventionsFilter.skill = null;
                }
            } else {
                interventionsFilter.skill = null;
            }
        } else {
            interventionsFilter.skill = null;
        }

        this.setState(
            {
                selectedInterventionSkill,
                interventionsFilter,
            },
            () => {
                this.handleGetInterventions();
            }
        );
    };

    handleChangeJobSelection = (event) => {
        const interventionsFilter = this.state.interventionsFilter;
        const selectedJobId = event.target.value;

        if (selectedJobId && selectedJobId != "") {
            interventionsFilter.jobId = selectedJobId;
        } else {
            interventionsFilter.jobId = null;
        }

        this.setState(
            {
                selectedJobId,
                interventionsFilter,
            },
            () => {
                this.handleGetInterventions();
            }
        );
    };

    handleUpdateShowHiddenInterventionFilter = () => {
        const showHiddenInterventions = this.state.showHiddenInterventions;
        this.setState({ showHiddenInterventions: !showHiddenInterventions });
    };

    handleUpdateHiddenIntervention = (id, hidden) => {
        const interventions = this.state.interventions;

        Promise.resolve(hidden ? this.props.onActionPlan.updateHideIntervention(id) : this.props.onActionPlan.updateUnHideIntervention(id)).then(() => {
            const intervention = interventions.find((it) => it.id == id);
            if (intervention) {
                intervention.hidden = hidden;
            }

            this.setState({ interventions: [...interventions] });
        });
    };

    handleConfirmActionNoSkillConfirmation = () => {
        this.setState(
            {
                noSkillConfirmationPass: true,
                showActionNoSkillConfirmationDialog: false,
            },
            () => {
                this.submitForm();
            }
        );
    };

    toggleActionNoSkillConfirmationDialog = () => {
        const { showActionNoSkillConfirmationDialog } = this.state;

        this.setState({ showActionNoSkillConfirmationDialog: !showActionNoSkillConfirmationDialog });
    };

    renderActionNoSkillConfirmationDialog = () => {
        const { showActionNoSkillConfirmationDialog } = this.state;

        return (
            <Modal isOpen={showActionNoSkillConfirmationDialog}>
                <ModalHeader toggle={this.toggleActionNoSkillConfirmationDialog}>
                    <ContentTranslator page="SfiaPlan" name="ActionNoSkillConfirmationDialogTitle" contentText="Action No Skills Confirmation" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="ActionNoSkillConfirmationDialogNote" contentText="Confirming that you intend to add a new action without any skills selected. Continue?" />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmActionNoSkillConfirmation();
                        }}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.toggleActionNoSkillConfirmationDialog}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { optionsActionTypes, optionsActionSkills, selectedSkills, pickedOtherSkills, selectedOtherSkills, form, formListed, interventions, showHiddenInterventions } = this.state;
        const { jobLorProfiles, jobSkillProfiles, lors, categories, showHiddenSkills } = this.props;

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden || showHiddenSkills)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        const spreadedActionSkills = [].concat.apply(
            [],
            optionsActionSkills
                .filter((it) => it.skills.some((skill) => !skill.selected && !skill.actioned))
                .map((it, groupIndex) => {
                    return it.skills
                        .filter((skill) => !skill.selected && !skill.actioned && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(skill.skillCode))))
                        .map((skill) => {
                            return {
                                groupIndex,
                                skillName: skill.skillType == "Lor" ? this.contentTranslator(this.props.contentTranslations, "Lors", skill.skillName, skill.skillName) : this.contentTranslator(this.props.contentTranslations, "Skills", skill.skillName, skill.skillName),
                                skillCode: skill.skillCode,
                                skillLabel:
                                    skill.skillType == "Lor"
                                        ? `${this.contentTranslator(this.props.contentTranslations, "Lors", skill.skillName, skill.skillName)} ${skill.skillLevel}`
                                        : `${this.contentTranslator(this.props.contentTranslations, "Skills", skill.skillName, skill.skillName)} ${skill.skillLevel}`,
                                skillLevel: skill.skillLevel,
                                skillType: skill.skillType,
                            };
                        });
                })
        );

        const spreadedInterventions = interventions.map((intervention) => {
            return {
                id: intervention.id,
                group: `${intervention.companyId}${intervention.actionType}${intervention.name}${intervention.link ? intervention.link : ""}${intervention.duration ? intervention.duration : "0"}`,
                companyId: intervention.companyId,
                company: intervention.company,
                actionType: intervention.actionType,
                name: intervention.name,
                link: intervention.link,
                duration: intervention.duration,
                durationHours: intervention.durationHours,
                description: intervention.description,
                isActive: intervention.isActive,
                isValidated: intervention.isValidated,
                publishedOn: intervention.publishedOn,
                reviewOn: intervention.reviewOn,
                retireOn: intervention.retireOn,
                sfiaVersion: intervention.sfiaVersion,
                versionNumber: intervention.versionNumber,
                levelsString: intervention.levels
                    .sort((a, b) => `${a.skillsCode}${a.level}` > `${b.skillsCode}${b.level}`)
                    .map((level) => {
                        return `${level.skillsCode}${level.level}`;
                    })
                    .join(", "),
                levels: intervention.levels,
                lorLevelsString: intervention.lorLevels
                    .sort((a, b) => `${a.lorCode}${a.level}` > `${b.lorCode}${b.level}`)
                    .map((level) => {
                        return `${level.lorCode}${level.level}`;
                    })
                    .join(", "),
                lorLevels: intervention.lorLevels,
                hidden: intervention.hidden,
            };
        });

        const groupdSpreadedInterventions = _.groupBy(spreadedInterventions, function (item) {
            return item.group;
        });

        const resultInterventions = [];

        Object.keys(groupdSpreadedInterventions).forEach((key) => {
            //console.log(key, groupdSpreadedInterventions[key]);
            resultInterventions.push(groupdSpreadedInterventions[key][0]);
        });

        const selectedDefaultSkill = this.props.selectedDefaultSkill;
        let oddIndex = 0;

        return (
            <Container style={wrapperStyle}>
                {this.modalInterventionDetail()}
                {this.renderActionNoSkillConfirmationDialog()}
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.actionPlanUpdating ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <ButtonGroup>
                                    <Button
                                        id="btnSelectFromList"
                                        color={this.state.addMode === "Listed" ? "primary" : "secondary"}
                                        style={btnGroupStyle}
                                        onClick={() => this.handleActionClick("Listed")}
                                        className={"btn " + (this.state.addMode === "Listed" ? "btn-primary btnPrimary-Style" : "btn-light btnSecondary-Style")}
                                    >
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanAddPredefinedAction" contentText="Select Action From List" />
                                    </Button>
                                    <UncontrolledTooltip target={`btnSelectFromList`} placement="bottom">
                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanAddPredefinedActionTooltip" contentText="Select an Action form the pre-defined list of Actions that relate the relevant skill(s)" />
                                    </UncontrolledTooltip>
                                    <Button
                                        id="btnAddCustomAction"
                                        color={this.state.addMode === "Custom" ? "primary" : "secondary"}
                                        style={btnGroupStyle}
                                        onClick={() => this.handleActionClick("Custom")}
                                        className={"btn " + (this.state.addMode === "Custom" ? "btn-primary btnPrimary-Style" : "btn-light btnSecondary-Style")}
                                    >
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanAddCustomAction" contentText="Create a Custom Action" />
                                    </Button>
                                    <UncontrolledTooltip target={`btnAddCustomAction`} placement="bottom">
                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanAddCustomActionTooltip" contentText="Create a new Customised Action that relates to the relevant skill(s)" />
                                    </UncontrolledTooltip>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {this.state.addMode === "Listed" ? (
                            <Form>
                                <Row xs={1} sm={1} md={1} lg={3}>
                                    <Col xs={12} sm={12} md={12} lg={12} className="p-1" style={{ textAlign: "right" }}>
                                        <Link to="#" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => this.handleTogleShowFilter()}>
                                            <FontAwesomeIcon icon={faFilter} />
                                        </Link>
                                    </Col>
                                </Row>
                                {this.state.showFilter && this.interventionFormPanel()}
                                {this.state.loading ? (
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <Spinner size="lg" animation="border" role="status"></Spinner>
                                    </div>
                                ) : (
                                    <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden", padding: "10px" }}>
                                        {resultInterventions.map((intervention, index) => {
                                            if (showHiddenInterventions || (!showHiddenInterventions && !intervention.hidden)) {
                                                oddIndex++;
                                            }
                                            const odd = oddIndex % 2 == 1;
                                            return (
                                                <React.Fragment key={index}>
                                                    <div style={{ display: showHiddenInterventions || (!showHiddenInterventions && !intervention.hidden) ? "block" : "none" }}>
                                                        <Row xs={1} sm={1} md={1} lg={3} style={{ padding: "5px 10px", backgroundColor: odd && "#FFFFFF" }}>
                                                            <Col xs={12} sm={12} md={12} lg={12} className="p-1">
                                                                <Link to="#" style={{ color: "#0056b3", cursor: "pointer" }} onClick={() => this.handleShowInterventionDetail(intervention)}>
                                                                    {intervention.company.name} - {intervention.name}
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                        <Row xs={1} sm={1} md={1} lg={3} style={{ paddingLeft: "10px", paddingRight: "10px", backgroundColor: odd && "#FFFFFF" }}>
                                                            <Col xs={8} sm={8} md={8} lg={8} className="p-1">
                                                                {intervention.lorLevels.map((level, levellIndex) => `${levellIndex > 0 ? ", " : ""}${level.lorCode}${level.level}`)}
                                                                {intervention.lorLevels && intervention.lorLevels.length ? ", " : ""}
                                                                {intervention.levels.map((level, levellIndex) => `${levellIndex > 0 ? ", " : ""}${level.skillsCode}${level.level}`)}
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2} lg={2} className="p-1">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="radio" name="radiointervention" onClick={() => this.handleSelectIntervention(intervention)} />
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={2} lg={2} className="p-1" style={{ textAlign: "center" }}>
                                                                <Link id={`LinkIntervention${intervention.id}`} to="#" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => this.handleShowInterventionDetail(intervention)}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                </Link>
                                                                &nbsp;&nbsp;
                                                                <Link id={`LinkInterventionHidden${intervention.id}`} to="#" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => this.handleUpdateHiddenIntervention(intervention.id, !intervention.hidden)}>
                                                                    {intervention.hidden ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                                                                </Link>
                                                                <UncontrolledTooltip target={`LinkIntervention${intervention.id}`} placement="bottom">
                                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanInterventionInfo" contentText="Detail Info" />
                                                                </UncontrolledTooltip>
                                                                <UncontrolledTooltip target={`LinkInterventionHidden${intervention.id}`} placement="bottom">
                                                                    {intervention.hidden ? <ContentTranslator page="SfiaPlan" name="SfiaPlanUnhideIntervention" contentText="Unhide" /> : <ContentTranslator page="SfiaPlan" name="SfiaPlanHideIntervention" contentText="Hide" />}
                                                                </UncontrolledTooltip>
                                                            </Col>
                                                        </Row>
                                                        {intervention.link ? (
                                                            <Row xs={1} sm={1} md={1} lg={3} style={{ padding: "5px 10px", backgroundColor: odd && "#FFFFFF" }}>
                                                                <Col xs={9} sm={9} md={9} lg={9} className="p-1">
                                                                    {intervention.link && (
                                                                        <a href={intervention.link} target="_blank" rel="noreferrer">
                                                                            {intervention.link}
                                                                        </a>
                                                                    )}
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2} lg={2} className="p-1">
                                                                    {intervention.duration ? (
                                                                        <span style={{ paddingRight: "5px" }}>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanInterventionDuration", "{s1} days"), intervention.duration)}</span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {intervention.durationHours ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanInterventionDurationHours", "{s1} hours"), intervention.durationHours) : ""}
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            (intervention.duration || intervention.durationHours) && (
                                                                <React.Fragment>
                                                                    <Row xs={1} sm={1} md={1} lg={3} style={{ padding: "5px 10px", backgroundColor: odd && "#FFFFFF" }}>
                                                                        <Col xs={9} sm={9} md={9} lg={9} className="p-1"></Col>
                                                                        <Col xs={2} sm={2} md={2} lg={2} className="p-1">
                                                                            {intervention.duration ? (
                                                                                <span style={{ paddingRight: "5px" }}>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanInterventionDuration", "{s1} days"), intervention.duration)}</span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {intervention.durationHours ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanInterventionDurationHours", "{s1} hours"), intervention.durationHours) : ""}
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                )}
                                <div style={{ height: "20px" }}></div>
                                <FormGroup row>
                                    <Label sm={3} className="font-weight-bold" for="lblDescription">
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionDescription" contentText="Description" />
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" name="description" id={"inputDescription"} value={formListed.description.value ? formListed.description.value : ""} onChange={this.handleFormListedChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3} className="font-weight-bold" for="lblTargetDate">
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionTarget" contentText="Target Date" />
                                    </Label>
                                    <Col sm={9}>
                                        <DatePicker selected={formListed.target.value ? new Date(formListed.target.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleFormListedChangeDate("target", value)} className="form-control" isClearable />
                                        {formListed && formListed.target.touched && !formListed.target.valid && <span style={{ color: "#ff0000", fontSize: "12px" }}>Target date is mandatory</span>}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={3}></Col>
                                    <Col sm={9}>
                                        <Button
                                            style={{ width: "135px", height: "36px", fontSize: "14px" }}
                                            color="primary"
                                            className={`btn btn-primary ${this.state.isFormListedValid && this.state.selectedIntervention ? "btnPrimary-Style" : "btnDisabled-Style"}`}
                                            disabled={!(this.state.isFormListedValid && this.state.selectedIntervention)}
                                            onClick={this.submitFormListed}
                                        >
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonSaveAction" contentText="Save Action" />
                                        </Button>
                                        <Button style={{ width: "135px", height: "36px", fontSize: "14px", marginLeft: "10px" }} className="btn btn-light btnSecondary-Style" onClick={this.props.handleClose}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonCancel" contentText="Cancel" />
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        ) : (
                            <Form>
                                <Row xs={1} sm={1} md={1} lg={3}>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblName">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionName" contentText="Action Name" />
                                            </Label>
                                            <Input type="text" name="name" id={"name"} value={form.name.value} invalid={form.name.touched && !form.name.valid} onChange={this.handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblLastName">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionType" contentText="Type" />
                                            </Label>
                                            <Combobox
                                                data={optionsActionTypes}
                                                onSelect={(value) =>
                                                    this.handleChange({
                                                        target: {
                                                            name: "actionType",
                                                            value: value.name,
                                                        },
                                                    })
                                                }
                                                onChange={() => {}}
                                                textField="name"
                                                dataKey="name"
                                                defaultValue={optionsActionTypes[0]}
                                                style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row xs={1} sm={1} md={1} lg={3}>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblDescription">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionDescription" contentText="Description" />
                                            </Label>
                                            <Input type="text" name="description" id={"inputDescription"} value={form.description.value} onChange={this.handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblTargetDate">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionTarget" contentText="Target Date" />
                                            </Label>
                                            <DatePicker selected={form.target.value ? new Date(form.target.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("target", value)} className="form-control" isClearable />
                                            {form && form.target.touched && !form.target.valid && <span style={{ color: "#ff0000", fontSize: "12px" }}>Target date is mandatory</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row xs={1} sm={1} md={1} lg={3}>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblDuration">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionDurationWithInterval" contentText="Duration(Days)" />
                                            </Label>
                                            <Input type="text" name="duration" id={"inputDuration"} invalid={form.duration.touched && (!form.duration.valid || !form.duration.isValidFormat)} value={form.duration.value ? form.duration.value : ""} onChange={this.handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblDuration">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionDurationHoursWithInterval" contentText="Duration(Hours)" />
                                            </Label>
                                            <Input
                                                type="text"
                                                name="durationHours"
                                                id={"inputDurationHours"}
                                                invalid={form.durationHours.touched && (!form.durationHours.valid || !form.durationHours.isValidFormat)}
                                                value={form.durationHours.value ? form.durationHours.value : ""}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row xs={1} sm={1} md={1} lg={2}>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblWebsite">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionHyperlink" contentText="Hyperlink/URL" />
                                            </Label>
                                            <Input type="text" name="website" id={"inputWebsite"} value={form.website.value ? form.website.value : ""} onChange={this.handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblOtherSkills">
                                                <ContentTranslator page="SfiaPlan" name="SfiaActionOtherSkills" contentText="Other Skills" />
                                            </Label>
                                            <Row>
                                                <Col sm={10}>
                                                    <Combobox
                                                        data={spreadedActionSkills.sort((a, b) => a.groupIndex - b.groupIndex)}
                                                        onSelect={(value) => this.handleChangeOtherSkill(value)}
                                                        onChange={() => {}}
                                                        textField="skillLabel"
                                                        groupBy="skillName"
                                                        filter="contains"
                                                        placeholder="Select skill"
                                                        autoSelectMatches={true}
                                                        value={spreadedActionSkills.find((value) => value.skillCode + "-" + value.skillLevel == this.state.selectedOtherSkill)}
                                                        style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                                    />
                                                </Col>
                                                <Col sm={2} className="btn-add-other-skill">
                                                    <ButtonGroup>
                                                        <Button onClick={() => this.handleAddOtherSkill()} className="btn btn-white" disabled={!this.state.selectedOtherSkill}>
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="exampleCheck" check>
                                                <span>
                                                    <ContentTranslator page="SfiaPlan" name="SfiaActionApplicableSkills" contentText="Applicable Skills" />
                                                </span>
                                            </Label>
                                            <div>
                                                {jobLorProfiles &&
                                                    jobLorProfiles
                                                        .filter((it) => !it.actioned || (selectedDefaultSkill ? selectedDefaultSkill.skillCode == it.lorCode && selectedDefaultSkill.skillLevel == it.level : false))
                                                        .sort((a, b) => `${a.lorCode}${a.level}` > `${b.lorCode}${b.level}`)
                                                        .map((jobLorProfile, index) => {
                                                            const lor = lors.find((it) => it.lorCode == jobLorProfile.lorCode);
                                                            const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == jobLorProfile.level) : null;

                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div id={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} style={{ float: "left" }}>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            className="custom-160 add"
                                                                            name={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                            id={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                            checked={!!selectedSkills.find((it) => it.skillCode == jobLorProfile.lorCode && it.skillLevel == jobLorProfile.level)}
                                                                            label={`${jobLorProfile.lorCode}${jobLorProfile.level} - ${this.contentTranslator(this.props.contentTranslations, "Lors", jobLorProfile.lor, jobLorProfile.lor)} ${jobLorProfile.level}`}
                                                                            onChange={() => this.handleSkillCheckedChange(jobLorProfile.lor, jobLorProfile.lorCode, jobLorProfile.level, "Lor")}
                                                                            inline
                                                                        />
                                                                    </div>
                                                                    <UncontrolledTooltip target={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} placement="bottom">
                                                                        {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                                    </UncontrolledTooltip>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                {jobSkillProfiles &&
                                                    jobSkillProfiles
                                                        .filter((it) => availableSkillCodes.includes(it.skillCode) && (!it.actioned || (selectedDefaultSkill ? selectedDefaultSkill.skillCode == it.skillCode && selectedDefaultSkill.skillLevel == it.level : false)))
                                                        .sort((a, b) => `${a.skillCode}${a.level}` > `${b.skillCode}${b.level}`)
                                                        .map((jobSkillProfile, index) => {
                                                            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode)));
                                                            const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode));
                                                            const skill = subCategory && subCategory.skills.find((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode);
                                                            const skillLevel = skill && skill.levels.find((it) => it.level == jobSkillProfile.level);

                                                            let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                            skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${jobSkillProfile.skillCode} ${jobSkillProfile.level} Description`, skillLevelDescription);

                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div id={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`} style={{ float: "left" }}>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            className="custom-250"
                                                                            name={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                            id={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                            checked={!!selectedSkills.find((it) => it.skillCode == jobSkillProfile.skillCode && it.skillLevel == jobSkillProfile.level)}
                                                                            label={`${jobSkillProfile.skillCode}${jobSkillProfile.level} - ${this.contentTranslator(this.props.contentTranslations, "Skills", jobSkillProfile.skill, jobSkillProfile.skill)} ${jobSkillProfile.level}`}
                                                                            onChange={() => this.handleSkillCheckedChange(jobSkillProfile.skill, jobSkillProfile.skillCode, jobSkillProfile.level, "Skill")}
                                                                            inline
                                                                        />
                                                                    </div>
                                                                    <UncontrolledTooltip
                                                                        target={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                        placement="bottom"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: skillLevelDescription,
                                                                        }}
                                                                    ></UncontrolledTooltip>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {pickedOtherSkills && pickedOtherSkills.length > 0 && (
                                    <Row>
                                        <Col className="p-1">
                                            <FormGroup>
                                                <Label className="font-weight-bold" for="exampleCheck" check>
                                                    <span>
                                                        <ContentTranslator page="SfiaPlan" name="SfiaActionOtherSkills" contentText="Other Skills" />
                                                    </span>
                                                </Label>
                                                <div>
                                                    {pickedOtherSkills.map((pickedOtherSkill, index) => {
                                                        let tooltipDescription = "";

                                                        if (pickedOtherSkill.skillType == "Skill") {
                                                            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == pickedOtherSkill.skillName && it.skillsCode == pickedOtherSkill.skillCode)));
                                                            const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == pickedOtherSkill.skillName && it.skillsCode == pickedOtherSkill.skillCode));
                                                            const skill = subCategory && subCategory.skills.find((it) => it.name == pickedOtherSkill.skillName && it.skillsCode == pickedOtherSkill.skillCode);
                                                            const skillLevel = skill && skill.levels.find((it) => it.level == pickedOtherSkill.skillLevel);

                                                            let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                            tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${pickedOtherSkill.skillCode} ${pickedOtherSkill.skillLevel} Description`, skillLevelDescription);
                                                        } else {
                                                            const lor = lors.find((it) => it.lorCode == pickedOtherSkill.skillCode);
                                                            const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == pickedOtherSkill.skillLevel) : null;

                                                            if (lorLevel) {
                                                                tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                            }
                                                        }

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkOtherSkill${pickedOtherSkill.skillCode}${pickedOtherSkill.skillLevel}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className="custom-250"
                                                                        name={`chkOtherSkill${pickedOtherSkill.skillCode}${pickedOtherSkill.skillLevel}`}
                                                                        id={`chkOtherSkill${pickedOtherSkill.skillCode}${pickedOtherSkill.skillLevel}`}
                                                                        checked={!!selectedOtherSkills.find((it) => it.skillCode == pickedOtherSkill.skillCode && it.skillLevel == pickedOtherSkill.skillLevel)}
                                                                        label={`${pickedOtherSkill.skillCode}${pickedOtherSkill.skillLevel} - ${
                                                                            pickedOtherSkill.skillType == "Skill"
                                                                                ? this.contentTranslator(this.props.contentTranslations, "Skills", pickedOtherSkill.skillName, pickedOtherSkill.skillName)
                                                                                : this.contentTranslator(this.props.contentTranslations, "Lors", pickedOtherSkill.skillName, pickedOtherSkill.skillName)
                                                                        } ${pickedOtherSkill.skillLevel}`}
                                                                        onChange={() => this.handleOtherSkillCheckedChange(pickedOtherSkill.skillName, pickedOtherSkill.skillCode, pickedOtherSkill.skillLevel, pickedOtherSkill.skillType)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    target={`divChkOtherSkill${pickedOtherSkill.skillCode}${pickedOtherSkill.skillLevel}`}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: tooltipDescription,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="justify-content-center">
                                    <Col xs="auto">
                                        <Button style={{ width: "135px", height: "36px", fontSize: "14px" }} color="primary" className={`btn btn-primary ${this.state.isFormValid ? "btnPrimary-Style" : "btnDisabled-Style"}`} disabled={!this.state.isFormValid} onClick={this.submitForm}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonSaveAction" contentText="Save Action" />
                                        </Button>
                                        <Button style={{ width: "135px", height: "36px", fontSize: "14px", marginLeft: "10px" }} className="btn btn-light btnSecondary-Style" onClick={this.props.handleClose}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonCancel" contentText="Cancel" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        actionPlanUpdating: state.actionPlan.actionPlanUpdating,
        actionPlanUpdatingError: state.actionPlan.actionPlanUpdatingError,
        addedAction: state.actionPlan.addedAction,
        interventionsLoading: state.actionPlan.loading,
        interventionsError: state.actionPlan.error,
        interventions: state.actionPlan.interventions,

        lors: state.lor.lors,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,

        jobLoading: state.job.loading,
        jobError: state.job.error,
        jobs: state.job.jobs,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddAction);
